<template>
  <div>
    <div class="p-onlineClinicInterviewConfirmation__box">
      <div class="p-onlineClinicInterviewConfirmation__label">問診内容</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">どのような症状でご来院されましたか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.symptom}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">それはいつ頃からですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.fromWhen}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">保険証<br>※クリックすると拡大されます</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">
        <img :src="clinicReservationToCreate.interview.insuranceCardImage" @click="openImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
      </div>

      <div v-if="clinicReservationToCreate.interview.recipientCertificateImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.recipientCertificateImage" @click="openRecipientCertificateImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <!-- <div v-if="clinicReservationToCreate.interview.healthDiagnosisImage || clinicReservationToCreate.interview.isCopyHealthDiagnosisImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">健康診断画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.healthDiagnosisImage" :src="clinicReservationToCreate.interview.healthDiagnosisImage" @click="openHealthDiagnosisImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.health_diagnosis_image.url" @click="openHealthDiagnosisImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">健康診断画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div> -->
      <div class="p-onlineClinicInterviewConfirmation__labelInterview">保険会社への給付申請の予定はありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{ clinicReservationToCreate.interview.isInsuranceClaimPlanned ? 'はい' : 'なし' }}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">身長（cm）を入力してください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.height}} cm</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">体重（kg）を入力してください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.weight}} kg</div>
      
      <div v-if="isFemale">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在妊娠している、またはその可能性はありますか？</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.isPossibilityOfPregnancy}}</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">治療中、あるいは今までに病気や手術したことはありますか？</div>
      <div v-for="(item, index) in clinicReservationToCreate.interview.medicalHistorySelect" :key="`first-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
        <p v-if="item != 'その他'">{{item}}</p>
        <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.medicalHistory}}）</p>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">アレルギーはありますか？</div>
      <div v-for="(item, index) in clinicReservationToCreate.interview.allergySelect" :key="`second-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
        <p v-if="item != 'その他'">{{item}}</p>
        <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.allergy}}）</p>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">タバコは吸いますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{smokingText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるお薬はありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isCurrentMedicine == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isCurrentMedicine == 'あり'">あり<br>{{clinicReservationToCreate.interview.currentMedicine}}</p>

      <div v-if="clinicReservationToCreate.interview.medicineNotebookImage || clinicReservationToCreate.interview.isCopyMedicineNotebookImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.medicineNotebookImage" :src="clinicReservationToCreate.interview.medicineNotebookImage" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.medicine_notebook_image.url" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬の副作用が起きたことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isSideEffect == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isSideEffect == 'あり'">あり<br>{{clinicReservationToCreate.interview.sideEffect}}</p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ジェネリック医薬品がある場合、ジェネリック医薬品をご希望ですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.generic | convertGenericValueToText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるサプリメントはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview">
        {{clinicReservationToCreate.interview.isCurrentSupplement}}<br>{{clinicReservationToCreate.interview.currentSupplement}}
      </p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">医師に伝えたいことや質問がありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.otherInformation}}</div>

      <div v-if="clinicReservationToCreate.interview.otherUsefulImage || clinicReservationToCreate.interview.isCopyOtherUsefulImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.otherUsefulImage" :src="clinicReservationToCreate.interview.otherUsefulImage" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.other_useful_image.url" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

    </div>
    <div>
      <ImageView v-if="isImageViewModal" :image="clinicReservationToCreate.interview.insuranceCardImage"></ImageView>
      <ImageView v-if="isRecipientCertificateImageViewModal" :image="clinicReservationToCreate.interview.recipientCertificateImage" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyMedicineNotebookImage && isMedicineNotebookImageViewModal" :image="clinicReservationToCreate.interview.medicineNotebookImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyMedicineNotebookImage && isMedicineNotebookImageViewModal" :image="clinicReservationToCreate.latestInterview.medicine_notebook_image.url" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyOtherUsefulImage && isOtherUsefulImageViewModal" :image="clinicReservationToCreate.interview.otherUsefulImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyOtherUsefulImage && isOtherUsefulImageViewModal" :image="clinicReservationToCreate.latestInterview.other_useful_image.url" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyHealthDiagnosisImage && isHealthDiagnosisImageViewModal" :image="clinicReservationToCreate.interview.healthDiagnosisImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyHealthDiagnosisImage && isHealthDiagnosisImageViewModal" :image="clinicReservationToCreate.latestInterview.health_diagnosis_image.url" ></ImageView>
    </div>
  </div>
</template>
<script>
// TODO yes noの変換の仕組みはどこかにまとめたい
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';

const genericHash = {
  [Interview.generic.yes]: {
    labelText: 'はい',
  },
  [Interview.generic.no]: {
    labelText: 'いいえ',
  },
};
export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isImageViewModal: false,
      isRecipientCertificateImageViewModal: false,
      isMedicineNotebookImageViewModal: false,
      isOtherUsefulImageViewModal: false,
      isHealthDiagnosisImageViewModal: false,
    };
  },
  filters: {
    convertGenericValueToText(value) {
      return genericHash[value].labelText;
    },
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openImageModal() {
      this.isImageViewModal = true;
    },
    closeImageModal() {
      this.isImageViewModal = false;
      this.isRecipientCertificateImageViewModal = false;
      this.isMedicineNotebookImageViewModal = false;
      this.isOtherUsefulImageViewModal = false;
      this.isHealthDiagnosisImageViewModal = false;
    },
    openRecipientCertificateImageModal() {
      this.isRecipientCertificateImageViewModal = true;
    },
    openMedicineNotebookImageModal() {
      this.isMedicineNotebookImageViewModal = true;
    },
    openOtherUsefulImageModal() {
      this.isOtherUsefulImageViewModal = true;
    },
    openHealthDiagnosisImageModal() {
      this.isHealthDiagnosisImageViewModal = true;
    },
  },
  computed: {
    smokingText() {
      if (this.clinicReservationToCreate.interview.smoking == '過去に吸っていた'){
        if (!this.clinicReservationToCreate.interview.smokingPastAgeFrom)  this.clinicReservationToCreate.interview.smokingPastAgeFrom = ''
        if (!this.clinicReservationToCreate.interview.smokingPastAgeTo)  this.clinicReservationToCreate.interview.smokingPastAgeTo = ''
        if (!this.clinicReservationToCreate.interview.smokingPastNumber)  this.clinicReservationToCreate.interview.smokingPastNumber = ''
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smokingPastAgeFrom + '〜' + this.clinicReservationToCreate.interview.smokingPastAgeTo + '歳まで、1日' + this.clinicReservationToCreate.interview.smokingPastNumber + '本）'
      } else if (this.clinicReservationToCreate.interview.smoking == '吸っている') {
        if (!this.clinicReservationToCreate.interview.smokingPresentAgeFrom)  this.clinicReservationToCreate.interview.smokingPresentAgeFrom = ''
        if (!this.clinicReservationToCreate.interview.smokingPresentNumber)  this.clinicReservationToCreate.interview.smokingPresentNumber = ''
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smokingPresentAgeFrom + '歳から、1日' + this.clinicReservationToCreate.interview.smokingPresentNumber + '本）'
      } else {
        return this.clinicReservationToCreate.interview.smoking
      }
    },
    isFemale() {
      return this.clinicReservationToCreate.selectedFamilyProfile.sex == 'female'
    },
  }
};
</script>